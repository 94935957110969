import AppListing from "@/resources/js/Abstract/AppListing";

export default {
    name: "Account",
    mixins: [
        AppListing,
    ],
    data: function () {
        return {
            data: null,
        }
    },
    methods: {
        isAuthorized() {
            return this.responseStatus === 200 ? 'Authorized' : 'Unauthorized';
        },
    }
}